var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white q-pa-sm row"},[_c('div',{staticClass:"col-2",staticStyle:{"margin-top":"4px"}},[_c('m-image',{attrs:{"src":"https://s3-eu-west-2.amazonaws.com/mobilleo-bikehirelogos/brompton.svg"}})],1),_c('div',{staticClass:"col-6 q-pl-sm"},[_c('div',{staticClass:"text-subtitle2 text-primary"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.item.attributes.postcode)+" ")])]),_c('cta-buttons',{staticClass:"col-4 self-center text-right",attrs:{"latitude":_vm.item.latitude,"longitude":_vm.item.longitude,"bookable":{
      path: '/on-demand/bikehire',
      query: {
        origin: {
          label: _vm.item.name,
          value: ((_vm.item.latitude) + "," + (_vm.item.longitude))
        }
      }
    }}}),_c('q-separator',{staticClass:"q-mt-sm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }