<template>
  <div class="bg-white q-pa-sm row">
    <div class="col-8 q-pl-sm">
      <div class="text-subtitle2 text-primary">
        {{ item.name }}
      </div>
      <div class="text-caption">
        {{ item.description }}
      </div>
    </div>
    <cta-buttons
      :bookable="{
        path: `/on-demand/flights`,
        query: {
          origin: {
            label: item.name,
            value: item.attributes.iata
          }
        }
      }"
      class="col-4 self-center text-right"
    />
    <q-separator class="q-mt-sm" />
  </div>
</template>

<script>
import ctaButtons from './cta-buttons.vue'
export default {
  components: {
    ctaButtons
  },
  props: ['item']
}
</script>
