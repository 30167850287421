<template>
  <div class="bg-white q-pa-sm row">
    <div class="col-8 q-pl-sm">
      <div class="text-subtitle2 text-primary">
        {{ item.name }}
      </div>
      <div class="text-caption">
        {{ item.description }}
      </div>
      <q-rating
        v-if="!item.bookable && !item.attributes"
        :value="item.attributes.rating || 0"
        :max="5"
        color="primary"
        readonly
      />
    </div>
    <cta-buttons
      :latitude="item.latitude"
      :longitude="item.longitude"
      :bookable="item.bookable || {}"
      :attributes="item.attributes"
      class="col-4 self-center text-right"
    />
    <q-separator class="q-mt-sm" />
  </div>
</template>

<script>
import ctaButtons from './cta-buttons.vue'
export default {
  components: {
    ctaButtons
  },
  props: ['item']
}
</script>
