<template>
  <div class="map-search">
    <button class="back-btn" @click="$router.go(-1)">
      <q-icon name="fas fa-arrow-left" color="primary" size="20px" />
    </button>
    <m-location-search
      :placeholder="$t('change_area')"
      color="primary-tint"
      bg-color="white"
      class="search-btn"
      dense
      @selected="val => newLocationQuery(val)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { details as placeDetails } from 'api/places'

export default {
  data () {
    return {
      markers: []
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner',
      items: 'discovery/items',
      searchType: 'discovery/searchType'
    })
  },
  watch: {
    items (items) {
      if (items?.length > 0 && this.searchType !== 'bp') {
        this.$emit(
          'markers',
          [].concat(
            this.markers,
            items.map((item, markerIndex) => {
              return {
                key: `${item.name}-${markerIndex}`,
                position: {
                  lat: item.latitude,
                  lng: item.longitude
                },
                icon: `${process.env.VUE_APP_API_URL}/icon/marker/${this.$route.params.icon.name}.svg?width=24&height=36&color=${this.$route.params.color.replace('#', '%23')}`
              }
            })
          )
        )
        this.$nextTick(() => {
          this.$root.$children[0].$refs.layout.$refs.map.$refs.map.panBy(0, 100)
          this.$root.$el.querySelector('#card-0').scrollIntoView()
        })
      }
    }
  },
  mounted () {
    this.searchNearby()
  },
  methods: {
    async newLocationQuery (val) {
      const marker = val.context ? 'https://api.staging.mobilleo.net/icon/marker/w3w.svg?width=24&height=36&color=%23e11f26' : require('assets/markers/map-marker@0.75x.png')
      const { place_id, value } = val
      const location = {}
      if (place_id) {
        const { data } = await placeDetails(place_id)
        location.lat = data.geometry.location.lat
        location.lng = data.geometry.location.lng
      } else if (!val.latlng) {
        const [lat, lng] = value.split(',')
        location.lat = Number(lat)
        location.lng = Number(lng)
      } else {
        const { lat, lng } = val.latlng
        location.lat = lat
        location.lng = lng
      }
      this.setMapToGeolocation(location, marker)
    },
    setMapToGeolocation ({ lat, lng }, marker) {
      this.markers = []

      let useLat = lat
      let useLng = lng
      this.markers.push({
        position: { lat: useLat, lng: useLng },
        icon: {
          url: marker
        },
        class: 'marker'
      })

      this.$store.dispatch('map/setCenter', { lat: useLat, lng: useLng })
      this.$root.$children[0].$refs.layout.$refs.map.$mapObject.panTo({
        lat: useLat,
        lng: useLng
      })

      this.$nextTick(() => this.searchNearby())
    },
    async searchNearby () {
      try {
        await this.$store.dispatch('discovery/searchNearby', {
          location: this.$root.$children[0].$refs.layout.$refs.map.getCenterAsUrlValue(),
          searchType: this.$route.params.type
        })
      } catch (err) {
        const errorMsg = err.data?.detail?.[0] || 'Unexpected error'
        this.$q.notify({ message: this.$t('error.custom', { error: errorMsg }) })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.map-search
  margin 0 10px
  margin-top -60px

.search-btn
  top 76px
  background white
  border-radius 25px
  width 80vw
  display inline-block

.back-btn
  top 69px
  border-radius 50%
  margin-right 10px
  width 36px
  height 36px
  background rgba(255, 255, 255, 1)
  position relative
  border 1px solid rgba(0, 0, 0, 0.2)

</style>
