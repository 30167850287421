<template>
  <div class="relative-position">
    <div v-if="$route.params.type === 'ferry' && !featureFlagFerry" class="ferry-soon">
      <m-empty-state icon="fas fa-ship" padding-top="25px" label="Booking Service available soon" />
    </div>
    <div v-else class="home-search-list">
      <div class="row q-px-sm">
        <m-location-search
          v-if="showSearchBtn"
          :placeholder="$t('change_area')"
          color="primary-tint"
          bg-color="white"
          class="full-width search-btn"
          dense
          @selected="val => newLocationQuery = val"
        />
      </div>
      <div v-if="items && items.length === 0" class="bg-white q-mb-sm q-pa-sm row">
        <div class="col-12 q-pl-sm text-center self-center">
          <q-icon size="64px" name="fas fa-map-marker-question" />
          <div class="text-caption">
            {{ $t('nothing_found_nearby') }}
          </div>
        </div>
      </div>
      <component
        :is="`items-${cards.includes(item.type) ? item.type :'generic'}`"
        v-for="(item, key) in items"
        :key="key"
        :item="item"
        @book="book($event)"
      />
    </div>
  </div>
</template>

<script>
import { startCase } from 'lodash'
import { mapGetters } from 'vuex'
import { nearby } from 'api/locations'
import { details as placeDetails } from 'api/places'
import itemsGeneric from './item-generic.vue'
import itemsRental from './item-rental.vue'
import itemsCarclub from './item-carclub.vue'
import itemsBus from './item-bus.vue'
import itemsTram from './item-tram.vue'
import itemsTube from './item-tube.vue'
import itemsRail from './item-train.vue'
import itemsFlight from './item-flight.vue'
import itemsBikehire from './item-bikehire.vue'
import itemsFerry from './item-ferry.vue'
import { MEmptyState, MSelectQuery, MLocationSearch } from 'components/'
import { hasPermission } from 'utils/authentication'

export default {
  name: 'Home',
  components: {
    itemsGeneric,
    itemsRental,
    itemsCarclub,
    itemsBus,
    itemsTram,
    itemsTube,
    itemsRail,
    itemsFlight,
    itemsBikehire,
    itemsFerry,
    MEmptyState,
    MSelectQuery,
    MLocationSearch
  },
  data () {
    return {
      items: null,
      cards: [
        'rental', 'carclub', 'bus', 'tram', 'rail', 'tube', 'flight', 'bikehire', 'ferry'
      ],
      markers: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      partner: 'partner',
      storeItems: 'discovery/items'
    }),
    input () {
      return startCase(this.$route.params.label)
    },
    url () {
      return process.env.VUE_APP_API_URL
    },
    fallback () {
      return require('assets/missing-32.png')
    },
    showSearchBtn () {
      return true
      // return this.items && this.items[0] && this.cards.filter(type => type !== 'tube').includes(this.items[0].type)
    },
    newLocationQuery: {
      get () {
        return null
      },
      async set (val) {
        const marker = val.context ? 'https://api.staging.mobilleo.net/icon/marker/w3w.svg?width=24&height=36&color=%23e11f26' : require('assets/markers/map-marker@0.75x.png')
        const { place_id, value } = val
        const location = {}
        if (place_id) {
          const { data } = await placeDetails(place_id)
          location.lat = data.geometry.location.lat
          location.lng = data.geometry.location.lng
        } else if (!val.latlng) {
          const [lat, lng] = value.split(',')
          location.lat = Number(lat)
          location.lng = Number(lng)
        } else {
          const { lat, lng } = val.latlng
          location.lat = lat
          location.lng = lng
        }
        this.setMapToGeolocation(location, marker)
      }
    },
    featureFlagFerry () {
      return hasPermission('feature.mfd1.263.ferry.flow')
    }
  },
  watch: {
    items (items, old) {
      this.$root.$children[0].$refs.layout.$refs.sheet.scrollToLow()

      if (items && items.length > 1 && this.$route.params.type !== 'bp') {
        this.$emit(
          'markers',
          [].concat(
            items.map((item, markerIndex) => {
              return {
                key: `${item.name}-${markerIndex}`,
                position: {
                  lat: item.latitude,
                  lng: item.longitude
                },
                icon: `${process.env.VUE_APP_API_URL}/icon/marker/${this.$route.params.icon.name}.svg?width=24&height=36&color=${this.$route.params.color.replace('#', '%23')}`
              }
            }),
            this.markers
          )
        )
        this.$nextTick(() => {
          this.$root.$children[0].$refs.layout.$refs.map.$refs.map.panBy(0, 100)
        })
      }
    }
  },
  activated () {
    this.markers = []
    this.searchNearby()
  },
  methods: {
    searchNearby () {
      if (this.$route.params.type === 'ferry' && !this.featureFlagFerry) {
        this.$root.$children[0].$refs.layout.$refs.sheet.scrollToHigh()
        return
      }
      if (this.$route.params.type === 'bp') {
        this.$store.dispatch('discovery/searchNearby', { searchType: 'bp', fresh: true })
          .then(() => {
            this.items = this.storeItems
          })
        return
      }
      this.$emit('loading', true)
      if (this.$attrs.results) {
        this.$emit('loading', false)
        this.items = this.$attrs.results
      } else {
        nearby({
          location: this.$root.$children[0].$refs.layout.$refs.map.getCenterAsUrlValue(),
          type: this.$route.params.type
        }).then(response => {
          this.items = response.data
          this.$emit('loading', false)
        })
      }
    },
    setZoom (val) {
      const gMap = this.$root.$children[0].$refs.layout.$refs.map.$mapObject
      const zoom = gMap.zoom
      gMap.setZoom(zoom + val)
    },
    setMapToGeolocation ({ lat, lng }, marker) {
      this.markers = []

      let useLat = lat
      let useLng = lng
      this.markers.push({
        position: { lat: useLat, lng: useLng },
        icon: {
          url: marker
        },
        class: 'marker'
      })

      this.$store.dispatch('map/setCenter', { lat: useLat, lng: useLng })
      this.$root.$children[0].$refs.layout.$refs.map.$mapObject.panTo({
        lat: useLat,
        lng: useLng
      })

      this.$nextTick(() => this.searchNearby())
    }
  },
  beforeRouteLeave (to, from, next) {
    this.items = null
    this.$emit('markers', undefined)
    next()
  }
}
</script>

<style lang="stylus" scoped>
.home-search-list .row:first-child
  padding-top 16px
</style>
