<template>
  <div class="home-details-list">
    <div class="bg-white row top-box">
      <h6 v-if="type === 'bus'" class="col-12 text-center q-pb-sm">
        {{ busTitle }} <br> ({{ busBound }})
      </h6>
      <h6 v-if="type === 'rail'" class="col-12 text-center q-pb-sm">
        {{ trainTitle }}
      </h6>
      <div class="col-12 button-box">
        <q-btn
          v-if="latitude && longitude"
          unelevated outline
          align="center"
          icon="fas fa-directions"
          color="primary"
          size="sm"
          tag="a"
          target="_blank"
          :label="$t('directions')"
          class="text-white action-button"
          @click="openURL(`https://www.google.com/maps/dir/${latLng.latitude+','+latLng.longitude}/${latitude},${longitude}/`)"
        />
        <q-btn
          unelevated
          align="center"
          :icon="type === 'rail' ? 'fas fa-ticket-alt' : 'far fa-search' "
          color="primary"
          size="sm"
          :label="type === 'rail' ? $t('book') : $t('search')"
          class="text-white action-button"
          @click="$router.push(bookable).catch(() => {})"
        />
      </div>
    </div>
    <m-timetable-card
      v-for="item in departures"
      :key="item.service_id"
      :item="item"
    />
    <q-footer v-if="['gohi'].includes(partner.slug) && type === 'bus'" class="buy-ticket-footer">
      <q-btn
        unelevated
        align="center"
        color="primary"
        icon="fas fa-ticket-alt"
        :label="$t('public_transport_ticket')"
        class="buy-ticket"
        @click="buyTicket"
      />
    </q-footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openURL } from 'quasar'
import MTimetableCard from 'components/MTimetableCard'

export default {
  name: 'Details',
  components: {
    MTimetableCard
  },
  data () {
    return {
      type: null,
      origin: this.$route.params.origin,
      latLng: this.$route.params.latLng.latLng,
      latitude: this.$route.params.latLng.latitude,
      longitude: this.$route.params.latLng.longitude,
      bookable: this.$route.params.bookable
    }
  },
  computed: {
    ...mapGetters({
      departures: 'discovery/departures',
      partner: 'partner'
    }),
    busTitle () {
      const [label, bound] = this.origin.label.split(' - ')
      if (bound) return label
      return this.origin.label
    },
    busBound () {
      const [, bound] = this.origin.label.split(' - ')
      if (bound) return bound
      return null
    },
    trainTitle () {
      return `${this.origin.label} (${this.origin.value})`
    }
  },
  watch: {
    departures () {
      if (process.env.NODE_ENV !== 'test') {
        this.$root.$children[0].$refs.layout.$refs.sheet.scrollToFull()
      }
    }
  },
  async beforeMount () {
    this.$emit('loading', true)
    switch (this.bookable.path) {
    case '/on-demand/trains': this.type = 'rail'
      break
    case '/on-demand/bus': this.type = 'bus'
      break
    default: this.type = 'undefined'
    }

    const item = {
      type: this.type,
      description: this.origin.value,
      attributes: { atcocode: this.origin.atcocode }
    }

    try {
      await this.$store.dispatch('discovery/setTimetable', item)
      this.$emit('markers', [{
        key: 'bus-stop',
        position: { lat: this.latitude, lng: this.longitude },
        icon: `${process.env.VUE_APP_API_URL}/icon/marker/${this.$route.params.from.icon.name}.svg?width=24&height=36&color=${this.$route.params.from.color.replace('#', '%23')}`
      }])
    } catch (err) {
      console.log(err)
    }

    this.$emit('loading', false)
  },
  methods: {
    openURL,
    buyTicket () {
      this.$store.commit('ondemand/clear')
      this.$router.push({ name: 'public-transport-form' })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$emit('markers', undefined)
    next()
  }
}
</script>

<style lang="stylus" scoped>
.home-details-list
  padding 16px 0 60px
.top-box
  padding 0 5vw
.button-box
  display flex
  justify-content space-around
  padding 5px 0 10px
.action-button
  width 120px
.buy-ticket
  width 100%
h6
  font-size 110%
  margin 0
.buy-ticket-footer
  padding 10px 10px calc(64px + env(safe-area-inset-bottom)) 10px
  background white
</style>
