<template>
  <div>
    <q-btn
      unelevated outline
      align="center"
      icon="fas fa-directions"
      color="primary"
      size="sm"
      tag="a"
      target="_blank"
      :label="$t('directions')"
      class="text-white q-mb-sm"
      @click="openURL(`https://www.google.com/maps/dir/${latLng.latitude+','+latLng.longitude}/${latitude},${longitude}/`)"
    />

    <q-btn
      v-if="bookable.plannable"
      unelevated
      align="center"
      color="primary"
      size="sm"
      :label="$t('view')"
      class="text-white"
      @click="$router.push(bookable).catch(() => {})"
    />

    <q-btn
      v-else-if="bookable.path"
      unelevated
      align="center"
      icon="fas fa-ticket-alt"
      color="primary"
      size="sm"
      :label="$t('book')"
      class="text-white"
      @click="$router.push(bookable).catch(() => {})"
    />

    <q-btn
      v-else-if="attributes && attributes.url"
      unelevated
      align="center"
      color="primary"
      size="sm"
      :label="$t('website')"
      class="text-white"
      @click="openURL(`${attributes.url}`)"
    />

    <q-btn
      v-else
      unelevated
      align="center"
      icon="fas fa-concierge-bell"
      color="primary"
      size="sm"
      :label="$t('concierge.concierge')"
      class="text-white"
      @click="$router.push({hash: '/concierge', query: { type: 'question' } })"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openURL } from 'quasar'
export default {
  props: {
    bookable: {
      type: Object
    },
    attributes: {
      type: Object
    },
    latitude: Number,
    longitude: Number
  },
  computed: {
    ...mapGetters({
      latLng: 'geolocate/latLng'
    })
  },
  methods: {
    openURL,
    book () {
      this.$router.push({
        path: `/on-demand/car-hire`,
        query: {
          pickup: {
            label: this.item.name,
            value: this.item.attributes.branch_code
          }
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-btn
    width 105px
    & >>> .q-btn__wrapper
      padding 4px 8px
</style>
