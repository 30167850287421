<template>
  <div class="bg-white q-pa-sm">
    <div class="q-pl-sm row">
      <div class="text-primary col-7" @click="$router.push(bookable).catch(() => {})">
        <q-icon name="train" color="primary" />
        {{ item.name }}
      </div>
      <q-btn
        unelevated
        icon="info"
        color="primary"
        size="sm"
        :label="$t('train.live_departures')"
        class="text-white"
        @click="$router.push({name: 'home-details', params: { origin: bookable.query.origin, bookable, latLng: {latLng, latitude: item.latitude, longitude: item.longitude}, from: $route.params}})"
      />
    </div>
    <q-separator class="q-mt-sm" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['item'],
  computed: {
    ...mapGetters({
      latLng: 'geolocate/latLng'
    }),
    bookable () {
      return {
        path: '/on-demand/trains',
        query: {
          origin: {
            label: this.item.name,
            value: this.item.attributes.atoc
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.row
  justify-content space-between
  align-items center
.q-btn
  border-radius 8px
</style>
