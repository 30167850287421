<template>
  <div class="bg-white q-pa-sm row">
    <div class="col-8 q-pl-sm">
      <div class="text-subtitle2 text-primary">
        {{ item.name }}
      </div>
      <div class="text-caption">
        {{ item.description }}
      </div>
    </div>
    <cta-buttons
      :latitude="item.latitude"
      :longitude="item.longitude"
      :bookable="bookable"
      class="col-4 self-center text-right"
    />
    <q-separator class="q-mt-sm" />
  </div>
</template>

<script>
import authentication from 'mixins/authentication'
import ctaButtons from './cta-buttons.vue'
export default {
  components: {
    ctaButtons
  },
  mixins: [authentication],
  props: ['item'],
  computed: {
    bookable () {
      if (!this.hasPermission('search.tram')) return null

      return {
        path: `/on-demand/tram`,
        plannable: true,
        query: {
          origin: {
            label: this.item.name,
            value: `${this.item.latitude},${this.item.longitude}`
          }
        }
      }
    }
  }
}
</script>
