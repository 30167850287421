<template>
  <div class="bg-white q-pa-sm row">
    <div class="col-2" style="margin-top: 4px">
      <m-image :src="item.attributes.logo_url" />
    </div>
    <div class="col-6 q-pl-sm">
      <div class="text-subtitle2 text-primary">
        {{ item.description }}
      </div>
      <div class="text-caption">
        {{ item.name }}
      </div>
    </div>
    <cta-buttons
      :latitude="item.latitude"
      :longitude="item.longitude"
      :bookable="{
        path: '/on-demand/carclub',
        query: {
          origin: {
            label: item.description,
            value: `${item.latitude},${item.longitude}`
          }
        }
      }"
      class="col-4 self-center text-right"
    />
    <q-separator class="q-mt-sm" />
  </div>
</template>

<script>
import ctaButtons from './cta-buttons.vue'
export default {
  components: {
    ctaButtons
  },
  props: ['item']
}
</script>
