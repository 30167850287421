<template>
  <div class="ride-hailing-container">
    <button class="back-btn" @click="$router.go(-1)">
      <q-icon name="fas fa-arrow-left" color="primary" size="20px" />
    </button>
    <route-map
      class="map"
      @originClick="focusOrigin"
      @destinationClick="focusDestination"
    />
    <ride-form ref="form" />

    <transition :name="transitionName" appear mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import store from 'store'
import routeMap from './route-map'
import rideForm from './form'
export default {
  components: { routeMap, rideForm },
  data () {
    return {
      transitionName: null
    }
  },
  watch: {
    '$route' (to, from) {
      const toT = to.path.split('/')[to.path.split('/').length - 1]
      const fromT = from.path.split('/')[from.path.split('/').length - 1]
      this.transitionName = `from-${fromT}-to-${toT}`
    }
  },
  methods: {
    focusOrigin () {
      this.$refs.form.focusOrigin()
    },
    focusDestination () {
      this.$refs.form.focusDestination()
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('smartsearch/reset')
    next()
  }
}
</script>

<style lang="stylus" scoped>
.ride-hailing-container
  position relative
  overflow auto
  .map
   @media (max-width 768px)
    height calc(60vh - 60px)

.back-btn
  position absolute
  left 5px
  top 10px
  z-index 2
  border-radius 50%
  margin-right 10px
  width 36px
  height 36px
  background rgba(255, 255, 255, 1)
  border 1px solid rgba(0, 0, 0, 0.2)
</style>
