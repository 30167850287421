<template>
  <q-dialog ref="modal">
    <q-card class="q-pa-lg">
      <h6 class="text-center q-py-md">
        Taxi coming soon to DerbyGo
      </h6>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ComingSoonModal'

}
</script>
