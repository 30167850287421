<template>
  <GmapMap
    ref="map"
    :center="center"
    :zoom="zoom"
    :options="mapConfig"
    class="route-map"
    map-type-id="roadmap"
  >
    <GmapMarker
      ref="currentLocation"
      key="current-location"
      :position="mapMarkerCoords"
      :icon="{
        url: require(`assets/markers/deviceLocation.png`)
      }"
      class="marker"
    />
    <GmapMarker
      v-if="origin"
      ref="originMarker"
      :key="origin.label"
      :position="origin.latlng"
      :icon="{
        url: getIcon(origin)
      }"
      class="marker"
    />
    <GmapMarker
      v-if="destination"
      ref="destinationMarker"
      :key="destination.label"
      :position="destination.latlng"
      :icon="{
        url: getIcon(destination)
      }"
      class="marker"
    />
  </GmapMap>
</template>

<script>
import { mapGetters } from 'vuex'
import mapConfig from './map-config'
import { gmapApi } from 'gmap-vue'

export default {
  data () {
    return {
      mapConfig
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters({
      origin: 'smartsearch/mapOrigin',
      destination: 'smartsearch/mapDestination',
      whatThreeWords: 'smartsearch/whatThreeWords',
      latLng: 'geolocate/latLng',
      geoip: 'geoip',
      center: 'map/getCenterAsJson',
      zoom: 'map/getZoom'
    }),
    mapMarkerCoords () {
      if (this?.latLng?.latitude && this?.latLng?.longitude) return { lat: this.latLng.latitude, lng: this.latLng.longitude }
      return { lat: this.geoip.latitude, lng: this.geoip.longitude }
    }
  },
  watch: {
    origin: {
      handler (val) {
        this.setBounds()
      }
    },
    destination: {
      handler (val) {
        this.setBounds()
      }
    }
  },
  mounted () {
    this.$store.dispatch('map/resetMarkers')
    this.$store.dispatch('geolocate/get')
  },
  methods: {
    setBounds () {
      if (this.origin && this.destination) {
        const bounds = new this.google.maps.LatLngBounds()
        bounds.extend(this.origin.latlng)
        bounds.extend(this.destination.latlng)
        const padding = window.innerWidth > 768
          ? { bottom: 300, right: 300, top: 100 }
          : { top: 0, bottom: 0 }
        this.$refs.map.fitBounds(bounds, padding)
      } else if (this.origin && !this.destination) {
        this.$refs.map.panTo(this.origin.latlng)
        this.$refs.map.$mapObject.setZoom(15)
      } else if (this.destination && !this.origin) {
        this.$refs.map.panTo(this.destination.latlng)
        this.$refs.map.$mapObject.setZoom(15)
      } else {
        this.$refs.map.panTo(this.mapMarkerCoords)
        this.$refs.map.$mapObject.setZoom(15)
      }
    },
    getIcon (type) {
      if (type.w3w) {
        return 'https://api.staging.mobilleo.net/icon/marker/w3w.svg?width=24&height=36&color=%23e11f26'
      } else {
        return require('assets/markers/map-marker@0.5x.png')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.route-map
  height: calc(100vh - 64px);
  width: 100%;
  transition: height 0.3s;
  @media (max-width 768px)
    height calc(60vh - 65px)

.marker {
  width: 50px;
  height: 50px;
}
</style>
