<template>
  <div class="relative-position" style="border-radius: 16px">
    <mango-modal ref="mangoModal" />
    <coming-soon-modal ref="comingSoonModal" />
    <div
      class="welcomeBox shadow-1 row"
    >
      <div class="col column justify-center text-left">
        <p>{{ greeting }}</p>
        <h5 class="primary text-bold">
          {{ displayName || 'Mobilleo traveller' }}
        </h5>
        <p v-if="organisationName">
          {{ organisationName }}
        </p>
      </div>
      <div v-if="weather && weather.current" class="col column justify-center text-right">
        <p><q-icon :name="`fas ${weatherIcon}`" /></p>
        <p>{{ weather.current.temperature.text }} {{ weather.current.summary }}</p>
        <p>{{ weather.location | weatherLocation }}</p>
      </div>
      <div v-else-if="weather.searching" class="col column justify-center text-right">
        <p v-for="i in 3" :key="i">
          <q-skeleton type="text" />
        </p>
      </div>
      <div v-else-if="!latLng.latitude || !latLng.longitude" class="col column justify-center text-right">
        <p><q-icon name="fas fa-location-slash" /></p>
        <p>{{ $t('dashboard.location_disabled') }}</p>
      </div>
      <div v-else class="col column justify-center text-right">
        <p><q-icon name="fas fa-frown" /></p>
        <p>{{ $t('dashboard.weather_unavailable') }}</p>
      </div>
    </div>
    <div class="column absolute" style="right: 16px; top: -72px">
      <q-btn
        fab
        color="white"
        text-color="primary"
        icon="far fa-location"
        class="q-mb-md text-primary"
        @click="setMapToGeolocation"
      />
    </div>
    <div ref="nearby" class="bg-white nearby">
      <button
        rounded
        outline
        unelevated
        no-caps
        class="smartsearch-btn"
        @click="$router.push({
          name: 'smart-search',
          params: {
            origin: {
              label: 'Current Location',
              latitude: latLng.latitude || geoip.latitude,
              longitude: latLng.longitude || geoip.longitude,
            }
          }
        }).catch(() =>{})"
      >
        <q-avatar size="16px">
          <img src="~assets/mobilleo-logo-icon.png" alt="">
        </q-avatar>

        <em>{{ $t('where_to') }}</em> {{ $t('smartsearch.plan_your_journey_here') }}
      </button>
      <div class="text-center">
        <em>{{ $t('know_where_youre_going') }}</em> {{ $t('book_now') }}
      </div>

      <q-dialog
        v-if="['derbygo'].includes(authPartner.slug)"
        v-model="showSociabilityDialog"
        transition-show="jump-up"
        transition-hide="jump-down"
        @ok="externalUrl(authPartner.sociabilityUrl)"
      >
        <q-card class="booking-complete bg-primary text-white text-center" square>
          <q-card-section class="full-height">
            <q-avatar size="42px" rounded>
              <img :src="require(`assets/sociability.png`)" alt="sociability icon">
            </q-avatar>
            <p><strong>Coming soon to DerbyGo</strong></p>
            <p v-html="$t('derby.sociability_introduction')" />
          </q-card-section>
          <q-card-actions>
            <q-btn v-close-popup color="secondary" unelevated rounded class="full-width" :label="$t('derby.sociability_link')" @click.stop="externalUrl(authPartner.sociabilityUrl)" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!-- Mobility Nearby buttons -->
      <div :class="mobilityAndBookablesCombined.length%5 === 0 ? 'five-grid' : 'four-grid'">
        <div
          v-for="(item, key) in mobilityAndBookablesCombined"
          :key="key"
          :class="`text-center ${ isTypeEnabled(item.type, travelPreferences[typeToContentTypeMapper(item.type)]) ? null : 'is-disabled' }`"
          @click="isTypeEnabled(item.type, travelPreferences[typeToContentTypeMapper(item.type)]) ? handleClick(item) : null"
        >
          <template v-if="item.icon.resource === 'library'">
            <q-btn
              unelevated round
              :color="item.color.icon"
              :icon="item.icon.name && `fas fa-${item.icon.name}`"
              class="q-mb-sm"
              :text-color="item.color.text || 'white'"
            />
            <div class="text-subtitle2">
              {{ item.label }}
            </div>
          </template>
          <template v-else-if="item.icon.resource.includes('assets', 's3')">
            <q-btn
              unelevated round
              class="q-mb-sm nearby-icon"
            >
              <q-avatar v-if="item.icon.resource === 'assets'" size="42px" rounded>
                <img :src="require(`assets/${item.icon.name}`)" alt="" :style="{height: item.label === 'mango' ? '16px' : '42px'}">
              </q-avatar>
              <!-- TODO: S3 variant of <q-avatar> where the require() doesn't die -->
            </q-btn>
            <div class="text-subtitle2">
              {{ item.label }}
            </div>
          </template>
        </div>
      </div>
      <div v-if="['gohi'].includes(authPartner.slug)" class="text-center">
        <q-btn class="full-width" color="primary" icon="fas fa-ticket-alt" :label="$t('public_transport_ticket')" @click="buyTicket" />
      </div>
      <!-- Explore Nearby buttons -->
      <div class="text-center">
        <em>{{ $t('explore_nearby') }}</em>
      </div>
      <div class="three-grid">
        <div v-for="(item, key) in nearby" :key="key" class="text-center">
          <template v-if="item.icon.resource === 'library'">
            <q-btn
              unelevated round
              :icon="`fas fa-${item.icon.name}`"
              text-color="primary"
              @click="handleClick(item)"
            />
            <div class="text-subtitle2">
              {{ item.label }}
            </div>
          </template>
          <template v-else-if="item.icon.resource.includes('assets', 's3')">
            <q-btn
              v-if="item.type === 'dialog'"
              unelevated round
              class="q-mb-sm nearby-icon"
              @click="displayDialog(item.target)"
            >
              <q-avatar v-if="item.icon.resource === 'assets'" size="42px" rounded>
                <img :src="require(`assets/${item.icon.name}`)" alt="">
              </q-avatar>
            </q-btn>
            <q-btn
              v-else
              unelevated round
              class="q-mb-sm nearby-icon"
              @click="item.target ? externalUrl(item.target) : handleClick(item)"
            >
              <q-avatar v-if="item.icon.resource === 'assets'" size="42px" rounded>
                <img :src="require(`assets/${item.icon.name}`)" alt="">
              </q-avatar>
              <!-- TODO: S3 variant of <q-avatar> where the require() doesn't die -->
            </q-btn>
            <div class="text-subtitle2">
              {{ item.label }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <template v-if="authPartner && authPartner.slug === 'derbygo'">
      <q-banner dense class="bg-primary q-py-md q-pa-lg" @click="$router.push({path: 'profile', hash: '/contact', query: { type: 'question' } })">
        <template v-slot:avatar>
          <q-icon name="fas fa-comments-alt" size="32px" class="q-mr-sm" />
        </template>
        <div class="row">
          <div class="col-11">
            <div class="text-bold">
              {{ $t('derby.need_help') }}
            </div>
            <div class="text-subtitle2">
              {{ $t('derby.contact_us') }}
            </div>
          </div>
          <div class="col-1 text-right self-center">
            <q-icon name="fas fa-angle-right" size="24px" />
          </div>
        </div>
      </q-banner>
    </template>
    <template v-else>
      <q-banner dense class="bg-primary q-py-md q-pa-lg" @click="$router.push({path: 'profile', hash: '/concierge', query: { type: 'question' } })">
        <template v-slot:avatar>
          <q-icon name="fas fa-concierge-bell" size="32px" class="q-mr-sm" />
        </template>
        <div class="row">
          <div class="col-11">
            <div class="text-subtitle2">
              {{ $t('cannot_find') }}
            </div>
            <div class="text-bold">
              {{ $t('concierge.always_here') }}
            </div>
          </div>
          <div class="col-1 text-right self-center">
            <q-icon name="fas fa-angle-right" size="24px" />
          </div>
        </div>
      </q-banner>
    </template>
  </div>
</template>

<script>
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import { isTypeEnabled, typeToContentTypeMapper } from 'utils/contentTypes'
import dateUtil from 'utils/date-time'
import weatherMixin from 'mixins/weather'
import store from 'store'
import mangoModal from './mango-modal'
import comingSoonModal from './coming-soon-modal'

export default {
  name: 'Home',
  components: {
    mangoModal,
    comingSoonModal
  },
  filters: {
    weatherLocation (value) {
      const postCodeRegex = /(\b[A-Z]{1,2}\d{1,2}( ?\d?[A-Z]{2})?)(?=,|$)/
      const arr = value.split(',')
      arr.shift()
      let town = arr[0]
      town = town.replace(postCodeRegex, '')
      town = town.trim()
      const country = arr.pop()
      return `${town}, ${country}`
    }
  },
  mixins: [authentication, weatherMixin],
  data () {
    return {
      isTypeEnabled,
      typeToContentTypeMapper,
      showSociabilityDialog: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      geoip: 'geoip',
      latLng: 'geolocate/latLng',
      authPartner: 'partner',
      organisation: 'organisation',
      travelPreferences: 'preferences',
      weather: 'weather/all'
    }),
    bookables () {
      return this.authPartner.bookables
    },
    nearby () {
      return this.authPartner.nearby
    },
    mobilityAndBookablesCombined () {
      let arr = [...this.authPartner.mobility, ...this.authPartner.bookables].filter(item => !item.excludeFromMobilityNearby)
      return arr.sort((a, b) => a?.order - b?.order)
    },
    displayName () {
      return this.$store.getters.authentication.user.display_name
    },
    organisationName () {
      const org = this.$store.getters.authentication.organisation
      return org ? org.name : null
    },
    greeting () {
      const hour = dateUtil.newDate().c.hour
      const greetings = this.$t('dashboard.greetings')

      if (hour < 12) return greetings[0]
      else if (hour < 17) return greetings[1]
      else return greetings[2]
    },
    weatherIcon () {
      const current = this.weather.current
      return current ? this.getWeatherIcon(current.icon) : null
    }
  },
  watch: {
    latLng: {
      deep: true,
      handler: (value) => {
        store.dispatch('weather/updateWeatherInformation', { lat: value.latitude, lng: value.longitude })
      }
    }
  },
  activated () {
    this.$store.dispatch('addresses/getSavedLocations')
    this.$emit('markers', undefined)
    this.$root.$children[0].$refs.layout.$refs.sheet.scrollTo(
      this.$refs.nearby.getBoundingClientRect().height - 360
    )
    if (this.$route.params.booking_reference) loading.stop()
    const lat = this.latLng?.latitude || this.geoip.latitude
    const lng = this.latLng?.longitude || this.geoip.longitude
    store.dispatch('weather/updateWeatherInformation', { lat, lng })
  },
  methods: {
    externalUrl (url) {
      if (url) window.open(url, '_blank')
    },
    displayDialog (name) {
      switch (name) {
      case 'sociability':
        this.showSociabilityDialog = true
      }
    },
    setMapToGeolocation (location) {
      let lat
      let lng

      if (location) {
        lat = location.lat
        lng = location.lng
      }

      this.$root.$children[0].$refs.layout.$refs.map.$mapObject.setZoom(15)
      this.$root.$children[0].$refs.layout.$refs.map.$mapObject.panTo({
        lat: lat || this.latLng.latitude || this.geoip.latitude,
        lng: lng || this.latLng.longitude || this.geoip.longitude
      })
    },
    setZoom (val) {
      const gMap = this.$root.$children[0].$refs.layout.$refs.map.$mapObject
      const zoom = gMap.zoom
      gMap.setZoom(zoom + val)
    },
    isUserDisabled (type) {
      return this.user.travel_preferences[type] ? this.user.travel_preferences[type].hidden : false
    },
    handleClick (item) {
      // Show modal for unibus
      if (item.label === 'mango') {
        return this.$refs.mangoModal.$refs.modal.show()
      }
      // Taxi coming soon for derbygo
      if (item.label === 'Taxi' && this.$store.getters['partner'].slug === 'derbygo') {
        return this.$refs.comingSoonModal.$refs.modal.show()
      }

      let searchType = item.type
      const latitude = this.latLng.latitude || this.geoip.latitude
      const longitude = this.latLng.longitude || this.geoip.longitude
      if (['ride-hailing', 'hotel', 'drt'].includes(item.type)) {
        return this.$router.push({
          name: `ondemand-${item.type}`,
          params: {
            origin: {
              label: this.$t('current_location'),
              latitude,
              longitude,
              value: `${latitude},${longitude}`
            }
          }
        }).catch(() => {})
      }
      if (['trains', 'car-hire', 'flights'].includes(item.type)) {
        searchType = item.pref
      }
      if (searchType === 'bp') {
        this.setMapToGeolocation({ lat: 51.497566, lng: 0.005855 })
      }
      if (searchType === 'parking' && this.$store.getters['partner'].slug === 'derbygo') {
        this.setMapToGeolocation({ lat: 52.936820, lng: -1.498037 })
      }

      if (this.hasPermission('feature.mobility.nearby.cards')) {
        return this.$router.push({
          name: 'home-cards',
          params: {
            type: searchType,
            label: item.label,
            icon: item.icon,
            color: item.color.marker
          }
        })
      }

      return this.$router.push({
        name: 'home-search',
        params: {
          type: searchType,
          label: item.label,
          icon: item.icon,
          color: item.color.marker
        }
      })
    },
    buyTicket () {
      this.$store.commit('ondemand/clear')
      this.$router.push({ name: 'public-transport-form' })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'home-search') {
      this.$root.$children[0].$refs.layout.$refs.sheet.scrollToBottom()
      setTimeout(next, 100)
    } else {
      next()
    }
  }
}
</script>

<style lang="stylus" scoped>
.text-white
  color #fff

.bg-white
  background #fff

.nearby
  z-index 10
  padding 16px
  border-radius 16px
  padding-top 24px
  display grid
  grid-auto-rows auto
  grid-row-gap 24px
  align-items center

.five-grid
  display grid
  grid-template-columns repeat(5, 1fr)
  grid-row-gap 16px

.four-grid
  display grid
  grid-template-columns repeat(4, 1fr)
  grid-row-gap 16px

.three-grid
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-row-gap 16px

.smartsearch-btn
  justify-self center
  width 95%
  background-color white
  color #666
  border solid #ddd 0.5px
  padding 8px 0
  border-radius 16px
  display flex
  align-items center
  justify-content center
  em
    margin 0 4px 0 8px

em
  color convert(brandColor)

.welcomeBox
  z-index -1
  position fixed
  left 0
  right 0
  top 64px
  background-color white
  padding 16px 24px
</style>
