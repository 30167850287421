<template>
  <div>
    <q-card class="bg-none shotl-form">
      <div class="choose-route">
        <q-icon
          name="fas fa-location"
          color="primary"
          size="24px"
        />
        <m-location-search
          ref="originField"
          no-saved
          clearable
          :placeholder="$t('input.starting_location')"
          :value="originField"
          :show-icon="false"
          :input-style="{'font-size': '110%'}"
          style="margin-bottom:-4px; margin-top: 4px"
          @selected="val => originField = val"
          @open="() => tall = true"
          @close="() => tall = false"
        />
        <button class="swap" @click="swapLocations">
          <q-icon
            name="fas fa-sort-alt"
            size="24px"
          />
        </button>
        <q-icon
          name="fas fa-map-marker"
          color="primary"
          size="24px"
        />
        <m-location-search
          ref="destinationField"
          no-saved
          clearable
          :placeholder="$t('input.destination')"
          :value="destinationField"
          :show-icon="false"
          :input-style="{'font-size': '110%'}"
          style="margin-bottom:-4px; margin-top: 4px"
          @selected="val => destinationField = val"
          @open="() => tall = true"
          @close="() => tall = false"
        />
      </div>
      <m-unified-users v-model="people" />
      <leave-now
        :departing="parameters.depart"
        :disabled="disabled"
        @depart="val => setTime(val)"
        @submit="submit"
      />
    </q-card>
  </div>
</template>

<script>
import { MLocationSearch, LeaveNow, MUnifiedUsers } from 'components/'
import date from 'utils/date-time'
import loading from 'utils/loading'
import { mapGetters } from 'vuex'
const { addToDate, toCivilDateTime, adjustDate } = date
import flightModel from 'pages/ondemand/flights/model'
import authentication from 'mixins/authentication'

export default {
  name: 'SmartsearchMobile',
  components: { MLocationSearch, LeaveNow, MUnifiedUsers },
  mixins: [authentication],
  data () {
    return {
      tall: false,
      origin: null,
      destination: null
    }
  },
  computed: {
    ...mapGetters({
      latLng: 'geolocate/latLng',
      parameters: 'smartsearch/parameters',
      loading: 'smartsearch/loading',
      errors: 'smartsearch/errors'
    }),
    originField: {
      get () {
        return this.parameters.origin
      },
      async set (val) {
        this.$store.dispatch('smartsearch/setMapOrigin', val)
      }
    },
    destinationField: {
      get () {
        return this.parameters.destination
      },
      set (val) {
        this.$store.dispatch('smartsearch/setMapDestination', val)
      }
    },
    people: {
      get () {
        return {
          requester: this.parameters.requester,
          traveller: this.parameters.user
        }
      },
      set (val) {
        this.$store.dispatch('smartsearch/setRequester', val.requester)
        this.$store.dispatch('smartsearch/setTraveller', val.traveller)
      }
    },
    disabled () {
      return !this.parameters.origin || !this.parameters.destination
    }
  },
  watch: {
    loading (value, old) {
      if (value === true) {
        const partner = this.$store.getters.partner
        loading.start({
          message: this.$t('loading.smartsearch'),
          partnerSlug: partner.slug,
          spinnerColor: partner.hex
        })
      }
      if (old === true && value === false) {
        loading.stop()
        if (this.errors) {
          this.$q.dialog({
            title: this.$t('error.search_unsuccessful'),
            message: this.$t('error.try_later'),
            color: 'primary',
            ok: true,
            cancel: this.$t('leave_feedback')
          })
            .onCancel(() => {
              var win = window.open('https://mobilleo.com/resources/support/', '_blank')
              win.focus()
            })
        } else {
          this.$router.push({ name: 'smart-search-results' }).catch(err => console.log(err))
        }
      }
    }
  },
  mounted () {
    if (process.env.NODE_ENV !== 'test') { this.$refs.originField.$refs.input.focus() }
    // Default "Now" to 15 minutes from now
    this.setTime(toCivilDateTime(addToDate(date.now(), { minutes: 15 })))
    // Default starting location to "Current Location"
    const { latitude, longitude } = this.latLng
    // Unless latitude or longitude are null (location disabled)
    if (latitude && longitude) {
      this.originField = {
        label: 'Current Location',
        latLng: {
          lat: () => latitude,
          lng: () => longitude
        },
        value: `${latitude},${longitude}`
      }
    }
  },
  methods: {
    submit () {
      const mapped = {
        ...this.parameters
      }

      // Store details now so we can direct straight to results later
      Promise.all([
        this.$store.dispatch('ondemand/stash', {
          parameters: {
            ...flightModel(),
            ...mapped,
            outbound: mapped.depart,
            inbound: mapped.return,
            pickup: {
              location: {
                value: mapped.origin.value
              },
              date: mapped.depart
            },
            dropoff: {
              location: {
                value: mapped.destination.value
              },
              date: mapped.return || addToDate(adjustDate(mapped.depart, { hours: 9, minutes: 0, seconds: 0, milliseconds: 0 }), { days: 1 })
            }
          }
        }),
        this.$store.dispatch('carClub/setTraveller', mapped.user),
        this.$store.dispatch('carClub/setRequester', mapped.requester),
        this.$store.dispatch('carClub/setSearchLocation', {
          label: mapped.origin.label,
          value: mapped.origin.value
        }),
        this.$store.dispatch('carClub/setSearchStartDateTime', date.newDate(mapped.depart)),
        this.$store.dispatch('carClub/setSearchEndDateTime', addToDate(mapped.depart, { hours: 2 })),
        this.$store.dispatch('rideHailing/setTraveller', mapped.user),
        this.$store.dispatch('rideHailing/setRequester', mapped.requester),
        this.$store.dispatch('rideHailing/setOrigin', {
          label: mapped.origin.label,
          value: mapped.origin.value
        }),
        this.$store.dispatch('rideHailing/setDestination', {
          label: mapped.destination.label,
          value: mapped.destination.value
        }),
        this.$store.dispatch('rideHailing/setValue', { prop: 'time', payload: addToDate(mapped.depart, { minutes: 5 }) })
      ]).then(() => {
        if (this.hasPermission('feature.journey_planner')) {
          this.$store.dispatch('smartsearch/travelModes')
        } else {
          this.$store.dispatch('smartsearch/initialQuery')
        }
      })
    },
    setTime (time) {
      this.$store.dispatch('smartsearch/setTime', time)
    },
    swapLocations () {
      const oldOrigin = this.parameters.origin
      const oldDestination = this.parameters.destination

      this.destinationField = oldOrigin
      this.originField = oldDestination
    }
  }
}
</script>

<style lang="stylus" scoped>
.shotl-form
  right 35%
  width 30vw
  overflow-y scroll
  z-index 1
  padding 20px 10px 0
  @media (max-width: 768px) {
    width 100vw
    right 0
    left 0
    margin 0
  }

.q-field--with-bottom
  padding-bottom 0

.choose-route
  display grid
  grid-template-columns 36px auto 30px
  grid-column-gap 6px
  grid-template-rows auto
  align-items center

.swap
  grid-column-start 3
  grid-row-start 1
  grid-row-end 3
  color convert(brandColor)
  background white
  border none

.drt-button
  background white
  padding 6px
  border none
  display flex
  flex-direction column
  justify-content center
  align-items center

.confirm
  background convert(brandColor)
  color white
  font-size 20px
  width 100%
  grid-column-start 1
  grid-column-end 4
  border-radius 3px
  margin-bottom 24px
  line-height 1.715em
  padding 4px
  font-weight 500
  height 50px

</style>
