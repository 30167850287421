<template>
  <div class="bg-white q-pa-sm">
    <div class="q-pl-sm row">
      <div class="text-primary stop-name" @click="$router.push(bookable).catch(() => {})">
        <q-icon name="fas fa-bus" color="primary" class="q-pr-sm q-pt-xs" />
        {{ item.name }}
      </div>
      <q-btn
        unelevated
        icon="info"
        color="primary"
        size="sm"
        :label="$t('train.live_departures')"
        class="text-white"
        @click="$router.push({name: 'home-details', params: { origin: bookable.query.origin, bookable, latLng: {latitude: item.latitude, longitude: item.longitude}, from: $route.params }})"
      />
    </div>
    <q-separator class="q-mt-sm" />
  </div>
</template>

<script>
import authentication from 'mixins/authentication'

export default {
  mixins: [authentication],
  props: ['item'],
  computed: {
    bookable () {
      if (!this.hasPermission('search.bus')) return null
      return {
        name: 'ondemand-bus',
        query: {
          origin: {
            label: this.item.name,
            value: `${this.item.latitude},${this.item.longitude}`,
            atcocode: this.item.attributes.atcocode
          }
        },
        path: '/on-demand/bus'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.row
  justify-content space-between
  align-items flex-start
.q-btn
  border-radius 8px
.stop-name
  max-width 50%
  display flex
  align-items flex-start
</style>
