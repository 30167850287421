var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white q-pa-sm row"},[_c('div',{staticClass:"col-8 q-pl-sm"},[_c('div',{staticClass:"text-subtitle2 text-primary"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.item.description)+" ")])]),_c('cta-buttons',{staticClass:"col-4 self-center text-right",attrs:{"bookable":{
      path: "/on-demand/flights",
      query: {
        origin: {
          label: _vm.item.name,
          value: _vm.item.attributes.iata
        }
      }
    }}}),_c('q-separator',{staticClass:"q-mt-sm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }